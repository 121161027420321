import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Select from '@paljs/ui/Select';
import axios from 'axios';
import SEO from '../../components/SEO';
import Auth from '../../components/Auth';
import { baseUri, checkIsAdmin, hideLoader, requireCheck, showLoader } from '../../utils/utils';
import { GlobalDispatchData, GlobalStateData } from '../../storage/GlobalDataProvider';
import Error from '../404';
import toast, { Toaster } from 'react-hot-toast';
import { navigate } from 'gatsby';
export default function ChangePassword() {
  const authState = useContext(GlobalStateData);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const dispatch = useContext(GlobalDispatchData);
  const clearFormData = () => {
    setOldPassword('');
    setPassword('');
    setRePassword('');
  };
  const postApi = () => {
    const postData = {
      data: {
        password,
        repassword,
      },
    };
    toast.dismiss();
    let validate = requireCheck(postData);
    if (!validate) return;
    if (password !== repassword) {
      toast.error("New Password and Confirmation Password doesn't match");
      return;
    }
    if (password.length < 6) {
      toast.error('Password must be atleast 6 characters');
      return;
    }
    if (oldPassword === password) {
      toast.error('New password must be different from old one');
    }
    showLoader();
    axios
      .post(
        `${baseUri()}auth/change-password`,
        { password: password, currentPassword: oldPassword, passwordConfirmation: repassword },
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        dispatch({ type: 'APP_LOGIN', payload: response.data });
        navigate('/dashboard');
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        toast.error('You have entered a wrong password');
        clearFormData();
      });
  };

  return (
    <Auth title="Change Password" subTitle="Change the account password by entering the old Password">
      <SEO title="Change Password" />
      <form>
        <Toaster />
        <InputGroup fullWidth>
          <input
            type="password"
            placeholder="Current Password"
            minLength={6}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="oldpassword require"
          />
        </InputGroup>
        <InputGroup fullWidth>
          <input
            type="password"
            placeholder="New Password (min 6 characters)"
            minLength={6}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="password require"
          />
        </InputGroup>
        <InputGroup fullWidth>
          <input
            type="password"
            placeholder="Confirm Password (min 6 characters)"
            minLength={6}
            value={repassword}
            onChange={(e) => setRePassword(e.target.value)}
            className="rePassword require"
          />
        </InputGroup>
        <Button status="Success" type="button" shape="SemiRound" fullWidth onClick={postApi}>
          Change Password
        </Button>
        <Button type="button" shape="SemiRound" fullWidth onClick={() => navigate('/')}>
          Back to Home
        </Button>
      </form>
    </Auth>
  );
}
